/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
$cielo-azul-claro: #0099ff;
$cielo-azul-escuro: #1d4695;
$cielo-branco: #fff;
$cielo-title: #2a2c39;
$primary: rgba(6,138,235,.5);
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: $cielo-azul-escuro;
  text-decoration: none;
}

a:hover {
  color: $cielo-azul-claro;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: $cielo-azul-escuro;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: $cielo-branco;
  line-height: 0;
}
.back-to-top:hover {
  background: $cielo-azul-claro;
  color: $cielo-branco;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: $primary;
}
#header.header-transparent {
  background: transparent;
}
#header.header-scrolled {
  background: $primary;
}
#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}
#header .logo h1 a, #header .logo h1 a:hover {
  color: $cielo-branco;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 7px 15px;
  margin-left: 5px;
  font-size: 14px;
  color: $cielo-branco;
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 50px;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  background: $cielo-azul-escuro;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 5px 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: $cielo-branco;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  color: $cielo-title;
  margin: 0 5px;
  font-size: 14px;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: $cielo-branco;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: $cielo-branco;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $primary;
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: $cielo-branco;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  margin: 5px;
  font-size: 15px;
  color: $cielo-title;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: $cielo-branco;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: $cielo-branco;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: $cielo-branco;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(0deg, $cielo-azul-claro 0%, $cielo-azul-escuro 100%);
  padding: 0;
}
#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  height: 70vh;
  padding-top: 60px;
}
#hero h2 {
  color: $cielo-branco;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}
#hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: $cielo-branco;
}
#hero .carousel-control-prev, #hero .carousel-control-next {
  width: 10%;
}
#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}
#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: $cielo-branco;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid $cielo-azul-escuro;
}
#hero .btn-get-started:hover {
  background: $cielo-azul-escuro;
  color: $cielo-branco;
  text-decoration: none;
}
@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}
@media (max-width: 768px) {
  #hero .carousel-container {
    height: 90vh;
  }
  #hero h2 {
    font-size: 28px;
  }
}

.hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: $cielo-azul-claro;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: $cielo-title;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  padding-top: 80px;
}
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-left: 28px;
  position: relative;
}
.about .content ul li + li {
  margin-top: 10px;
}
.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: $cielo-azul-escuro;
  line-height: 1;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about .content .btn-learn-more {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: $cielo-azul-escuro;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid $cielo-azul-escuro;
}
.about .content .btn-learn-more:hover {
  background: $cielo-azul-escuro;
  color: $cielo-branco;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}
.features .nav-link {
  border: 1px solid #d4d6df;
  padding: 15px;
  transition: 0.3s;
  color: $cielo-title;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}
.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.features .nav-link:hover {
  color: $cielo-azul-escuro;
}
.features .nav-link.active {
  background: $cielo-azul-escuro;
  color: $cielo-branco;
  border-color: $cielo-azul-escuro;
}
@media (max-width: 768px) {
  .features .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}
@media (max-width: 575px) {
  .features .nav-link {
    padding: 15px;
  }
  .features .nav-link i {
    font-size: 24px;
  }
}
.features .tab-content {
  margin-top: 30px;
}
.features .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}
.features .tab-pane ul {
  list-style: none;
  padding: 0;
}
.features .tab-pane ul li {
  padding-bottom: 10px;
}
.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: $cielo-azul-escuro;
}
.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: $cielo-title;
  padding: 80px 0;
}
.cta h3 {
  color: $cielo-branco;
  font-size: 28px;
  font-weight: 700;
}
.cta p {
  color: $cielo-branco;
}
.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: $cielo-branco;
  background: $cielo-azul-escuro;
}
.cta .cta-btn:hover {
  background: $cielo-branco;
  color: $cielo-azul-escuro;
}
@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}
@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: $cielo-branco;
  box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;
}
.services .icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2);
}
.services .icon {
  position: absolute;
  left: -20px;
  top: calc(50% - 30px);
}
.services .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;
}
.services .title {
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .title a {
  color: $cielo-title;
  transition: ease-in-out 0.3s;
}
.services .title a:hover {
  color: $cielo-azul-escuro;
}
.services .description {
  font-size: 14px;
  margin-left: 40px;
  line-height: 24px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}
.faq .faq-list {
  padding: 0;
  list-style: none;
}
.faq .faq-list li {
  border-bottom: 1px solid #ececf1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.faq .faq-list .question {
  display: block;
  position: relative;
  font-family: $cielo-azul-escuro;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 25px;
  cursor: pointer;
  color: $cielo-azul-claro;
  transition: 0.3s;
}
.faq .faq-list i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -2px;
}
.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 25px;
}
.faq .faq-list .icon-show {
  display: none;
}
.faq .faq-list .collapsed {
  color: black;
}
.faq .faq-list .collapsed:hover {
  color: $cielo-azul-escuro;
}
.faq .faq-list .collapsed .icon-show {
  display: inline-block;
  transition: 0.6s;
}
.faq .faq-list .collapsed .icon-close {
  display: none;
  transition: 0.6s;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  margin-top: 70px;
  background: #f7f8f9;
  min-height: 40px;
}
.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
@media (max-width: 992px) {
  .breadcrumbs ol {
    margin-top: 10px;
  }
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #404356;
  content: "/";
}
@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: $cielo-azul-claro;
  color: $cielo-branco;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}
#footer h3 {
  font-size: 36px;
  font-weight: 700;
  color: $cielo-branco;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
}
#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}
#footer .social-links {
  margin: 0 0 40px 0;
}
#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: $cielo-azul-escuro;
  color: $cielo-branco;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .social-links a:hover {
  background: #bd5102;
  color: $cielo-branco;
  text-decoration: none;
}
#footer .copyright {
  margin: 0 0 5px 0;
}
#footer .credits {
  font-size: 13px;
}
